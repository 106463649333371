<template>
  <div :id="id" :class="className" :style="styleObject" :chartData="chartData"/>
</template>

<script>
import echarts from 'echarts';
import resize from "@/mirror-modules/resize";

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'radar-chart'
    },
    id: {
      type: String,
      default: 'radar-chart'
    },
    boxStyle: {
      type: Object,
      default: () => {
        return {width: '874px', height: '300px'};
      }
    },
    generalDetail: {
      type: Object,
      default: () => {
      }
    },
    chartData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      chart: null
    };
  },
  computed: {
    styleObject: function () {
      const obj = this.boxStyle;
      for (const attr in this.boxStyle) {
        // var current = getComputedStyle(this.boxStyle)[attr]
        // 提取单位,若存在单位，得到数组。若不存在单位，得到%
        var unit = this.boxStyle[attr].match(/[a-z]+$/);
        unit = unit ? unit[0] : '%';

        obj[attr] = unit !== '%' ? this.boxStyle[attr] : this.boxStyle[attr] + unit;
      }
      return obj;
    }
  },
  watch: {
    chartData () {
      this.initChart();
    }
  },
  mounted () {
    this.initChart();
  },
  beforeDestroy () {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart () {
      const source = {
        data: this.chartData,
        indicator: [
          {name: '总粉丝数', max: this.generalDetail.fans_count + 2000},
          {name: '直播场次', max: this.generalDetail.live_count + 10},
          {name: '直播场均观看', max: this.generalDetail.live_avg_watch + 2000},
          {name: '直播场均互动', max: this.generalDetail.live_avg_interactive + 2000},
          {name: '视频发布数', max: this.generalDetail.aweme_count + 20},
          {name: '视频条均播放', max: this.generalDetail.aweme_avg_play + 2000},
          {name: '视频条均互动', max: this.generalDetail.aweme_avg_interactive + 2000}
        ]
      };
      const buildSeries = function (data) {
        const helper = data.map((item, index) => {
          const arr = new Array(data.length);
          arr.splice(index, 1, item);
          return arr;
        });

        return [data, ...helper].map((item, index) => {
          return {
            type: 'radar',
            symbol: index === 0 ? 'circle' : 'none',
            symbolSize: 6,
            itemStyle: {
              color: '#4a65bf'
            },
            lineStyle: {
              color: index === 0 ? '#4a65bf' : 'transparent'
            },
            areaStyle: {
              color: index === 0 ? '#4a65bf' : 'transparent',
              opacity: 0
            },
            tooltip: {
              show: index === 0 ? false : true,
              formatter: function () {
                return (
                    source.indicator[index - 1].name + '：' + source.data[index - 1]
                );
              }
            },
            z: index === 0 ? 1 : 2,
            data: [item],
            name:'当前帐号',
          };
        });
      };

      const option = {
        legend: {
          data: ['当前帐号'],
          icon: 'roundRect',
          right: 0,
        },
        tooltip: {},
        radar: {
          name: {
            textStyle: {
              color: '#999',
              borderRadius: 3,
              padding: [3, 5]
            }
          },
          indicator: source.indicator
        },
        series: buildSeries(source.data),

      };

      this.chart = echarts.init(document.getElementById(this.id));
      this.chart.setOption(
          option
      );
    }
  }
};
</script>
