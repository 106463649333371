<template>
  <div>
    <div ref="container" class="container_content">
      <div class="container">
        <div class="main-title">
          <h1 class="title">{{ activity_name }} 学员诊断报告</h1>
          <!--<div class="time">诊断日期：{{ current_time }}</div>-->
        </div>
        <div class="info-wrap flex-center">
          <div class="info-box">
            <div class="content-box flex-center content-box-avatar">
              <div class="avatar-box">
                <div class="avatar" :style="`background-image: url('${infoDetail.avatar}')`" v-if="infoDetail.avatar">
                  <img :src="infoDetail.avatar" alt="头像">
                </div>
                <a-avatar :size="82" icon="user" style="margin-right: 10px" v-else/>
                <img class="platform"
                     :src="params.platform == '2' ? require(`@/assets/icon/kuaishou.png`) : params.platform == '1' ? require(`@/assets/icon/douyin.png`) : ''"/>
              </div>
              <div class="content">
                <div class="nickname">
                  <span class="name">{{ infoDetail.nickname || '--' }}</span>
                  <span class="txt">（{{ infoDetail.author_id || '--' }}）</span>
                </div>
                <div class="dealer-name">
                  <span class="name">{{ infoDetail.dealer_name || '--' }}</span>
                  <div class="principal_name_list_box">
                <span class="principal" v-show="principal_name_list.length > 0"
                      v-for="(item,index) in principal_name_list" :key="index">{{ item }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="data-box">
            <div class="box">
              <div class="num"> {{
                  infoDetail.fans_count_total ? (utils.tranNumber(infoDetail.fans_count_total, 2)) : '--'
                }}
              </div>
              <div class="title">粉丝量</div>
            </div>
            <div class="box">
              <div class="num">{{
                  infoDetail.aweme_count_total ? (utils.tranNumber(infoDetail.aweme_count_total, 2)) : '--'
                }}
              </div>
              <div class="title">作品数</div>
            </div>
            <div class="box">
              <div class="num">{{
                  infoDetail.digg_count_total ? (utils.tranNumber(infoDetail.digg_count_total, 2)) : '--'
                }}
              </div>
              <div class="title">获赞量</div>
            </div>
          </div>
        </div>
        <div class="list-wrap general-wrap">
          <div class="title-box">综合评价</div>
          <div class="content-box">
            <div class="box">
              <div class="general-box">
                <span class="title">综合评分：</span>
                <span class="score">{{ generalDetail.socre || '--' }}</span>
                <a-tag
                    v-if="generalDetail.socre"
                    :color="generalDetail.socre < 50 ? 'red' : generalDetail.socre >= 50 && generalDetail.socre < 60 ? 'cyan' : generalDetail.socre >= 60 && generalDetail.socre < 80 ? 'green' : generalDetail.socre >= 80 ? 'purple' : ''"
                    class="tag">
                  {{
                    generalDetail.socre < 50 ? '待提升' : generalDetail.socre >= 50 && generalDetail.socre < 60 ? '一般' : generalDetail.socre >= 60 && generalDetail.socre < 80 ? '良好' : generalDetail.socre >= 80 ? '优秀' : '--'
                  }}
                </a-tag>
              </div>
            </div>
            <div class="border"></div>
            <div class="chart-box">
              <radarChart
                  :id="'radar-chart'"
                  v-show="radarList && radarList.length > 0"
                  :chart-data="radarList"
                  :general-detail="generalDetail"
                  :box-style="{ width: '100%', height: '250px' }"
              />
            </div>
          </div>
        </div>
        <div class="list-wrap live-wrap">
          <div class="title-box">直播分析 <span class="date_time">（过去15天，{{ date15_start }} 至 {{ date15_end }}）</span></div>
          <div class="content-box">
            <div class="box">
              <div class="row">
                <div class="name">
                  <span>总</span><span>直</span><span>播</span><span>场</span><span>次：</span>
                </div>
                <div class="num">
                  {{ liveDetail.total_live_count ? utils.tranNumber(liveDetail.total_live_count, 2) : '--' }}
                  <a-tag
                      v-if="liveDetail.total_live_count"
                      :color="liveDetail.total_live_count < 5 ? 'red' : liveDetail.total_live_count > 5 && liveDetail.total_live_count < 8 ? 'cyan' : liveDetail.total_live_count > 8 && liveDetail.total_live_count < 14 ? 'green' : liveDetail.total_live_count > 15 ? 'purple' : ''"
                      class="tag">{{
                      liveDetail.total_live_count < 5 ? '待提升' : liveDetail.total_live_count > 5 && liveDetail.total_live_count < 8 ? '一般' : liveDetail.total_live_count > 8 && liveDetail.total_live_count < 14 ? '良好' : liveDetail.total_live_count > 15 ? '优秀' : ''
                    }}
                  </a-tag>
                </div>
              </div>
              <div class="row">
                <div class="name">
                  <span>有</span><span>效</span><span>直</span><span>播</span><span>场</span><span>次：</span>
                </div>
                <div class="num">{{
                    liveDetail.total_effective_live_count ? utils.tranNumber(liveDetail.total_effective_live_count, 2) : '--'
                  }}
                </div>
              </div>
              <div class="row">
                <div class="name">
                  <span>总</span><span>直</span><span>播</span><span>时</span><span>长：</span>
                </div>
                <div class="num">{{ (liveDetail.total_live_time / 60), 1 | disposeDecimal }}分钟</div>
              </div>
              <div class="row">
                <div class="name">
                  <span>人</span><span>均</span><span>停</span><span>留</span><span>时</span><span>长：</span>
                </div>
                <div class="num">{{ (liveDetail.avg_online_time / 60), 1 | disposeDecimal }}分钟</div>
              </div>
              <div class="row">
                <div class="name">
                  <span>平</span><span>均</span><span>在</span><span>线</span><span>人</span><span>数：</span>
                </div>
                <div class="num">{{
                    liveDetail.avg_online_user ? utils.tranNumber(liveDetail.avg_online_user, 2) : '--'
                  }}人
                </div>
              </div>
            </div>
            <div class="box">
              <div class="row">
                <div class="name">
                  <span>场</span><span>均</span><span>在</span><span>线</span><span>峰</span><span>值：</span>
                </div>
                <div class="num">
                  {{ liveDetail.avg_max_online_count ? utils.tranNumber(liveDetail.avg_max_online_count, 2) : '--' }}
                  人
                </div>
              </div>
              <div class="row">
                <div class="name">
                  <span>总</span><span>观</span><span>看：</span>
                </div>
                <div class="num">{{
                    liveDetail.total_watch_count ? utils.tranNumber(liveDetail.total_watch_count, 2) : '--'
                  }}
                </div>
              </div>
              <div class="row">
                <div class="name">
                  <span>场</span><span>均</span><span>观</span><span>看：</span>
                </div>
                <div class="num" v-if="params.platform == '1'">
                  {{ liveDetail.avg_watch_count ? utils.tranNumber(liveDetail.avg_watch_count, 2) : '--' }}
                  <a-tag
                      v-if="liveDetail.avg_watch_count"
                      :color="liveDetail.avg_watch_count < 500 ? 'red' : liveDetail.avg_watch_count >= 500 && liveDetail.avg_watch_count <= 2000 ? 'cyan' : liveDetail.avg_watch_count > 2000 && liveDetail.avg_watch_count <= 10000 ? 'green' : liveDetail.avg_watch_count > 10000 ? 'purple' : ''"
                      class="tag">
                    {{
                      liveDetail.avg_watch_count < 500 ? '待提升' : liveDetail.avg_watch_count >= 500 && liveDetail.avg_watch_count <= 2000 ? '一般' : liveDetail.avg_watch_count > 2000 && liveDetail.avg_watch_count <= 10000 ? '良好' : liveDetail.avg_watch_count > 10000 ? '优秀' : ''
                    }}
                  </a-tag>
                </div>
                <div class="num" v-if="params.platform == '2'">
                  {{ liveDetail.avg_watch_count ? utils.tranNumber(liveDetail.avg_watch_count, 2) : '--' }}
                  <a-tag
                      v-if="liveDetail.avg_watch_count"
                      :color="liveDetail.avg_watch_count < 800 ? 'red' : liveDetail.avg_watch_count >= 800 && liveDetail.avg_watch_count <= 1500 ? 'cyan' : liveDetail.avg_watch_count > 1500 && liveDetail.avg_watch_count <= 3000 ? 'green' : liveDetail.avg_watch_count > 3000 ? 'purple' : ''"
                      class="tag">
                    {{
                      liveDetail.avg_watch_count < 800 ? '待提升' : liveDetail.avg_watch_count >= 800 && liveDetail.avg_watch_count <= 1500 ? '一般' : liveDetail.avg_watch_count > 1500 && liveDetail.avg_watch_count <= 3000 ? '良好' : liveDetail.avg_watch_count > 3000 ? '优秀' : ''
                    }}
                  </a-tag>
                </div>
                <div class="num" v-if="params.platform == '3'">
                  {{ liveDetail.avg_watch_count ? utils.tranNumber(liveDetail.avg_watch_count, 2) : '--' }}
                  <a-tag
                      v-if="liveDetail.avg_watch_count"
                      :color="liveDetail.avg_watch_count < 600 ? 'red' : liveDetail.avg_watch_count >= 600 && liveDetail.avg_watch_count <= 1500 ? 'cyan' : liveDetail.avg_watch_count > 1500 && liveDetail.avg_watch_count <= 5000 ? 'green' : liveDetail.avg_watch_count > 5000 ? 'purple' : ''"
                      class="tag">
                    {{
                      liveDetail.avg_watch_count < 600 ? '待提升' : liveDetail.avg_watch_count >= 600 && liveDetail.avg_watch_count <= 1500 ? '一般' : liveDetail.avg_watch_count > 1500 && liveDetail.avg_watch_count <= 5000 ? '良好' : liveDetail.avg_watch_count > 5000 ? '优秀' : ''
                    }}
                  </a-tag>
                </div>
              </div>
              <div class="row">
                <div class="name">
                  <span>总</span><span>评</span><span>论</span><span>数：</span>
                </div>
                <div class="num">
                  {{ liveDetail.total_comment_count ? utils.tranNumber(liveDetail.total_comment_count, 2) : '--' }}
                </div>
              </div>
              <div class="row">
                <div class="name">
                  <span>场</span><span>均</span><span>评</span><span>论：</span>
                </div>
                <div class="num">{{
                    liveDetail.avg_comment_count ? utils.tranNumber(liveDetail.avg_comment_count, 2) : '--'
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="list-wrap video-wrap">
          <div class="title-box">短视频分析<span class="date_time">（过去30天，{{ date30_start }} 至 {{ date30_end }}）</span></div>
          <div class="content-box">
            <div class="box">
              <div class="row">
                <div class="name">
                  <span>发</span><span>布</span><span>量：</span>
                </div>
                <div class="num">
                  {{ videoDetail.aweme_count ? utils.tranNumber(videoDetail.aweme_count, 2) : '--' }}
                  <a-tag
                      v-if="videoDetail.aweme_count"
                      :color="videoDetail.aweme_count < 5 ? 'red' : videoDetail.aweme_count >= 5 && videoDetail.aweme_count < 9 ? 'cyan' : videoDetail.aweme_count >= 10 && videoDetail.aweme_count > 15 ? 'green' : videoDetail.aweme_count > 15 ? 'purple' : ''"
                      class="tag">
                    {{
                      (videoDetail.aweme_count < 5) ? '待提升' : (videoDetail.aweme_count >= 5 && videoDetail.aweme_count < 9) ? '一般' : (videoDetail.aweme_count >= 10) && (videoDetail.aweme_count > 15) ? '良好' : (videoDetail.aweme_count > 15) ? '优秀' : ''
                    }}
                  </a-tag>
                </div>
              </div>
              <div class="row">
                <div class="name">
                  <span>总</span><span>播</span><span>放</span><span>量：</span>
                </div>
                <div class="num">
                  {{ videoDetail.aweme_play_volume ? utils.tranNumber(videoDetail.aweme_play_volume, 2) : '--' }}
                </div>
              </div>
              <div class="row">
                <div class="name">
                  <span>总</span><span>点</span><span>赞：</span>
                </div>
                <div class="num">
                  {{ videoDetail.aweme_digg_count ? utils.tranNumber(videoDetail.aweme_digg_count, 2) : '--' }}
                </div>
              </div>
              <div class="row">
                <div class="name">
                  <span>总</span><span>评</span><span>论：</span>
                </div>
                <div class="num">
                  {{ videoDetail.aweme_comment_count ? utils.tranNumber(videoDetail.aweme_comment_count, 2) : '--' }}
                </div>
              </div>
            </div>
            <div class="box">
              <div class="row">
                <div class="name">
                  <span>爆</span><span>款</span><span>短</span><span>视</span><span>频：</span>
                </div>
                <div class="num">
                  {{ videoDetail.aweme_hot_count ? utils.tranNumber(videoDetail.aweme_hot_count, 2) : '--' }}
                </div>

              </div>
              <div class="row">
                <div class="name">
                  <span>最</span><span>高</span><span>播</span><span>放</span><span>量：</span>
                </div>
                <div class="num">
                  {{
                    videoDetail.max_aweme_play_volume ? utils.tranNumber(videoDetail.max_aweme_play_volume, 2) : '--'
                  }}
                </div>
              </div>
              <div class="row">
                <div class="name">
                  <span>平</span><span>均</span><span>点</span><span>赞：</span>
                </div>
                <div class="num">
                  {{
                    videoDetail.avg_aweme_digg_count ? utils.tranNumber(videoDetail.avg_aweme_digg_count, 2) : '--'
                  }}
                </div>
              </div>
              <div class="row">
                <div class="name">
                  <span>平</span><span>均</span><span>评</span><span>论：</span>
                </div>
                <div class="num">
                  {{
                    videoDetail.avg_aweme_comment_count ? utils.tranNumber(videoDetail.avg_aweme_comment_count, 2) : '--'
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_btn">
      <a-button type="primary" size="large" @click="downloadImg">下载图片</a-button>
    </div>
  </div>
</template>

<script>
import radarChart from './components/radarChart';
import utils from "@/utils/FormatUtil.js";
import moment from 'moment';
import html2canvas from "html2canvas";
import { getImageToBase64Data } from "@/utils/auth";

export default {
  components: {
    radarChart
  },
  data () {
    return {
      utils,
      params: {
        platform: this.$route.query.platform || '1',
        author_id: this.$route.query.author_id || null,
        start_time: this.$route.query.start_time || '',
        end_time: this.$route.query.end_time || ''
      },
      activity_name: this.$route.query.activity_name || '',
      current_time: '',
      infoDetail: {},
      generalDetail: {},
      radarList: [0, 0, 0, 0, 0, 0, 0],
      liveDetail: {},
      videoDetail: {},
      principal_name_list: [],
      date15_start: moment().subtract(15, 'days').format('MM月DD日'),
      date15_end: moment().subtract(1, 'days').format('MM月DD日'),
      date30_start: moment().subtract(30, 'days').format('MM月DD日'),
      date30_end: moment().subtract(1, 'days').format('MM月DD日')
    };
  },
  mounted () {
    this.getDiagnosticData(this.params);
  },
  methods: {
    getDiagnosticData (params) {
      this.$api.core.TrainingList.getDiagnosticData(params).then(res => {
        if (res.code === 0) {
          this.current_time = res.data.current_time;
          this.infoDetail = res.data.author_detail || {};
          this.infoDetail.avatar = res.data.author_detail.avatar || "";
          // this.infoDetail.avatar = 'https://sf1-cdn-tos.bdxiguastatic.com/img/user-avatar/3a9e295e73d693bcb8ca53a0b935a5d0~300x300.image';
          getImageToBase64Data(this.infoDetail.avatar);
          this.liveDetail = res.data.live_diagnostic_report || {};
          this.videoDetail = res.data.aweme_diagnostic_report || {};
          this.generalDetail = res.data.evaluation_score || {};
          this.principal_name_list = this.infoDetail.principal_name ? this.infoDetail.principal_name.split(",") : '';
          this.radarList = [
            this.generalDetail.fans_count,
            this.generalDetail.live_count,
            this.generalDetail.live_avg_watch,
            this.generalDetail.live_avg_interactive,
            this.generalDetail.aweme_count,
            this.generalDetail.aweme_avg_play,
            this.generalDetail.aweme_avg_interactive
          ];
        } else {
          console.log('获取报告信息失败');
        }
      });
    },
    downloadImg () {
      let content = this.$refs.container;
      if (content) {
        // 转化canvas时设置(allowTaint: false,（允许污染画布） useCORS: true（img加上crossOrigin="anonymous"属性从而去申请跨域请求头，使用代理需要设置为false）)
        html2canvas(content, {useCORS: true, allowTaint: true}).then(res => {
          const imgBase64 = res.toDataURL('image/jpeg'); // toDataURL将canvas转化为图片
          let link = document.createElement('a');
          link.style.display = 'none';
          link.href = imgBase64;
          link.setAttribute('download', `${ this.activity_name ? this.activity_name : '' }${ this.infoDetail.dealer_name ? this.infoDetail.dealer_name : '' }学员诊断报告.jpg`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
    } // 下载图片
  }
}
;
</script>

<style lang="less" scoped>
.container_content {
  width: 100%;
  height: 100%;
  padding-bottom: 60px;
}

.footer_btn {
  padding: 200px 0 80px 0;
  display: flex;
  justify-content: center;
}

.date_time {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

.container {
  width: 1100px;
  margin: 0 auto;
}

.main-title {
  padding-bottom: 10px;
  margin: 30px 0;
  border-bottom: 2px solid #333;
  padding-top: 40px;

  .title {
    color: #333;
    font-size: 28px;
    text-align: center;
  }

  .time {
    color: #333;
    text-align: right;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-wrap {
  margin: 30px 0;

  .info-box {
    display: flex;
    justify-content: flex-end;
    padding-right: 50px;
    width: 45%;
    box-sizing: border-box;
  }

  .data-box {
    display: flex;
    align-items: center;
    padding-left: 30px;
    width: 45%;
    box-sizing: border-box;

    .title {
      font-size: 16px;
      color: #2A90F4;
    }

    .box {
      padding: 0 20px;
      width: 25%;
      text-align: center;
      box-sizing: border-box;

      & + .box {
        border-left: 1px solid #eee;
      }
    }

    .num {
      font-size: 16px;
      font-weight: bold;
      color: #333;
    }
  }

  .avatar-box {
    position: relative;
    width: 80px;
    height: 80px;

    .avatar {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
      background-color: rgba(0, 0, 0, .2);

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .platform {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 23px;
    }
  }

  .content {
    flex: 1;
    margin-left: 20px;

    .nickname {
      margin-bottom: 8px;

      .name {
        font-size: 16px;
        font-weight: bold;
        color: #333;
      }

      .txt {
        display: inline-block;
      }
    }

    .dealer-name {
      display: flex;
      font-size: 12px;

      .name {
        color: #666;
        line-height: 1.5em;
        margin-right: 10px;
      }

      .principal {
        display: inline-block;
        padding: 0 10px;
        height: 20px;
        line-height: 20px;
        color: #eee;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 6px;
      }

      .principal_name_list_box {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        span {
          margin: 2px 3px;
        }
      }
    }
  }
}

.list-wrap {
  padding: 30px 0;
  border-top: 1px solid #eee;

  .title-box {
    padding: 0 15px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    border-left: 5px solid #1890ff;
  }

  .content-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 1000px;
  }

  .chart-box {
    width: 50%;
  }

  .box {
    padding: 0 50px;
    max-width: 50%;
    min-width: 35%;
    box-sizing: border-box;
  }

  .general-box {
    display: flex;
    align-items: flex-end;

    .title {
      padding-bottom: 7px;
      font-weight: bold;
      color: #333;
    }

    .score {
      margin: 0 15px 0 10px;
      font-size: 70px;
      line-height: 1;
    }

    .tag {
      margin-bottom: 8px;
    }
  }

  .border {
    width: 1px;
    height: 200px;
    background-color: #eee;
  }

  .row {
    display: flex;
    align-items: center;
    margin: 10px 0;
    line-height: 25px;

    .name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 5px;
      width: 105px;
      font-weight: bold;
      color: #333;
    }

    .num {
      flex: 1;
    }

    .tag {
      margin-left: 10px;
    }
  }
}

//.content-box-avatar{
//  display: flex;
//  align-items: flex-start;
//  justify-content: space-between;
//  margin: 0 auto;
//  width: 1000px;
//}
</style>
